import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    this.boundClickOutside = this.clickOutside.bind(this);
    window.addEventListener('click', this.boundClickOutside);
    window.addEventListener('touchend', this.boundClickOutside);
  }

  disconnect() {
    window.removeEventListener('click', this.boundClickOutside);
    window.removeEventListener('touchend', this.boundClickOutside);
  }

  clickOutside(event) {
    if (!this.isExpanded() || this.element.contains(event.target)) {
      return;
    }
    this.hide();
  }

  hide() {
    this.menuTarget.classList.add('opacity-0', 'scale-95');
    this.menuTarget.classList.remove('opacity-100', 'scale-100');
    this.menuTarget.setAttribute('aria-expanded', false);
    setTimeout(() => {
      this.menuTarget.classList.add('hidden');
    }, 5);
  }

  show() {
    this.menuTarget.classList.remove('hidden');
    setTimeout(() => {
      this.menuTarget.classList.remove('opacity-0', 'scale-95');
      this.menuTarget.classList.add('opacity-100', 'scale-100');
      this.menuTarget.setAttribute('aria-expanded', true);
    }, 5);
  }

  isExpanded() {
    return this.menuTarget.getAttribute('aria-expanded') === 'true';
  }

  toggle(event) {
    event.preventDefault();
    if (this.isExpanded()) {
      this.hide();
    } else {
      this.show();
    }
  }
}
