import { Controller } from '@hotwired/stimulus';

/**
 * Controller for adding and removing form array inputs.
 */
export default class FormArrayInputController extends Controller {
  static targets = [
    'template',
    'target',
    'wrapper',
  ];

  add(event) {
    event.preventDefault();
    this.targetTarget.insertAdjacentHTML('beforebegin', this.templateTarget.innerHTML);
  }

  /* eslint-disable class-methods-use-this */
  remove(event) {
    event.preventDefault();
    event.target.closest('[data-form-array-input-target=wrapper]').remove();
  }
  /* eslint-enable class-methods-use-this */
}
