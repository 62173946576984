import { Controller } from '@hotwired/stimulus';

/**
 * Controller responsible for a single query condition
 * Renders the form field of the condition based on the given subquery. Uses the definition and type values to set the
 * corresponding input fields. Communicates changes to the subquery to the query builder controller using an outlet.
 * Has a path to denote the location of the subquery in the query. This is used by the query builder to apply the
 * changes to the query. It has the following targets:
 * - Column: The database column to which the condition will be applied.
 * - Type: The operator of the condition that will be applied, e.g. `equals`, `contains`, `greater_than`.
 * - Input: The value that is used to compare the operator with.
 * Connects to data-controller="query-condition"
 */
export default class QueryConditionController extends Controller {
  static outlets = ['query-builder', 'search-select-component'];

  static values = {
    // Object representation of the JSON of this condition
    query: Object,
    path: String,
    definition: Object,
    types: Object,
  };

  static targets = [
    'column',
    'type',
    'input',
    'searchSelect', // The entire search select
    'select', // The select within the search select
  ];

  /**
   * Sets the colum and type select box and sets the input.
   */
  connect() {
    this.createColumnOptions();
    this.columnTarget.value = this.queryValue.column;

    this.createTypeOptions();
    this.typeTarget.value = this.queryValue.node_type;

    this.updateInput();
    this.updateType();
  }

  /**
   * Adds the condition columns of the definition as column select options.
   */
  createColumnOptions() {
    this.definitionValue.columns.forEach((column) => {
      this.columnTarget.add(new Option(column.human_name, column.name));
    });
    this.columnTarget.name = this.pathValue.concat('.column');
  }

  /**
   * Create the type select options. The available options are based on the definition for the current column.
   */
  createTypeOptions() {
    this.typeTarget.innerHTML = '';

    const column = this.columnDefinition();

    if (column !== undefined && 'type' in column) {
      this.typesValue[column.type].comparators.forEach((comparator) => {
        this.typeTarget.add(new Option(comparator.text, comparator.value));
      });
      this.typeTarget.disabled = false;
    } else {
      this.typeTarget.disabled = true;
    }
    this.typeTarget.name = this.pathValue.concat('.type');
  }

  /**
   * Replace the condition value input target with an input or search select element of the correct type and value.
   * The input type is based on the definition for the column. The input value is read from `this.queryValue`.
   */
  updateInput() {
    const column = this.columnDefinition();
    const columnType = column?.type;

    if (columnType === 'enum') {
      this.renderSelectConditionValue(column.collection);
    } else {
      this.inputTarget.name = this.pathValue.concat('.value');
      // If columnType is undefined, it falls back to a text input (see `inputType`)
      const type = QueryConditionController.inputType(columnType);
      this.inputTarget.type = type;
      this.inputTarget.classList.toggle('w-full', (type !== 'checkbox'));
      if (type === 'checkbox') {
        this.inputTarget.checked = this.queryValue.value;
      } else {
        this.inputTarget.value = this.queryValue.value;
      }

      this.inputTarget.classList.toggle('hidden', false);
      this.searchSelectTarget.classList.toggle('hidden', true);
      this.inputTarget.disabled = (columnType == null);
    }
  }

  /**
   * Returns the definition of the column that is set as queryValue column.
   */
  columnDefinition() {
    return this.definitionValue.columns.find(({ name }) => name === this.queryValue.column);
  }

  /**
   * Use the column type (from the definition) to determine what kind of input should be rendered.
   * Note that this method is not used for the enum column type since it being rendered as select instead of input.
   */
  static inputType(columnType) {
    switch (columnType) {
      case 'boolean':
        return 'checkbox';
      case 'date':
        return 'date';
      case 'datetime':
        return 'datetime-local';
      case 'number':
        return 'number';
      default:
        return 'text';
    }
  }

  /**
   * Render the condition value as search select (instead of an input). If collection is an array, the array is rendered
   * as options for the search select. Else, it is considered to be an URL which the search select uses as API endpoint
   * to retrieve options from.
   * @param collection An array of options or an API url to retrieve options from.
   */
  renderSelectConditionValue(collection) {
    // Hide the input and show the select instead.
    this.inputTarget.classList.toggle('hidden', true);
    this.searchSelectTarget.classList.toggle('hidden', false);
    // Set the name of the select based on the path.
    this.selectTarget.name = this.pathValue.concat('.value');

    if (Array.isArray(collection)) {
      delete this.searchSelectTarget.dataset.searchSelectComponentApiUrlValue; // Disable api search
      this.conditionOptionElements(collection);
    } else {
      // Only keep the value="" option to allow users to unselect options
      Array.from(this.selectTarget.options).filter((option) => option.value !== '').forEach((o) => o.remove());

      if (this.queryValue.value != null) {
        // Add selected option with value but without text. The text value should be retrieved from the API.
        const option = new Option('', this.queryValue.value);
        option.dataset.searchSelectComponentTarget = 'option';
        option.selected = true;
        option.setAttribute('selected', 'true');
        this.selectTarget.append(option);
      }
      // Collection is an URL to retrieve the collection from.
      this.searchSelectTarget.dataset.searchSelectComponentApiUrlValue = collection;
    }
  }

  /**
   * Adds options to the input target and selects a default option. The options are created either directly by mapping
   * the collection or by retrieving the collection from an API.
   * @param {Object[]|string} collection Either an array of items that should be shown als condition input options or
   * an API url from which these items can be retrieved. The item objects should consist of a text and a value.
   */
  conditionOptionElements(collection) {
    // Use setTimeOut to ensure the search select controller is loaded and handles added options.
    // For example, without this the collection options are not added correctly when switching from a column with
    // an API search select to a column with a collection search select.
    setTimeout(() => {
      // An empty option is added to the collection such that a user can select no value.
      const items = [{ text: '', value: '' }, ...collection];

      // Note the innerHTML is replaced at once instead of appending the options one by one, because the latter results
      // in performance issues (slow).
      this.selectTarget.innerHTML = items.map((item) => {
        const option = new Option(item.text, item.value.toString());
        option.dataset.searchSelectComponentTarget = 'option';
        return option.outerHTML;
      }).join('');
      this.selectValueOption();
      this.searchSelectTarget.dispatchEvent(new Event('refresh'));
    });
  }

  /**
   * Returns the value of the condition input. In case of a checkbox, true is returned if the checkbox is checked and
   * false otherwise since the value of a checkbox is the same regardless whether it is checked or not.
   */
  inputValue() {
    return this.inputTarget.type === 'checkbox' ? this.inputTarget.checked : this.inputTarget.value;
  }

  /**
   * Selects the value option. If no option is selected it selects the first one and sends this change to the query
   * builder.
   */
  selectValueOption() {
    this.selectTarget.value = this.queryValue.value;

    // If no value is selected, the first option is selected
    if (this.selectTarget.selectedIndex === -1) {
      this.selectTarget.selectedIndex = 0;
      const { value } = this.selectTarget;
      this.queryValue = { ...this.queryValue, value };
      this.queryBuilderOutlet.setQueryProperty(this.pathValue, { value });
    }
  }

  /**
   * Handles the update of the value of the column. It changes the type options according to the new value of the
   * column and messages the query controller about the update.
   */
  updateColumn() {
    this.queryValue = { ...this.queryValue, column: this.columnTarget.value };

    // Create options that are allowed for the selected column.
    this.createTypeOptions(); // This method uses this.queryValue.column, hence we set it beforehand.
    this.typeTarget.selectedIndex = 0; // The first option is selected by default when changing the column.

    // The value is reset when changing the column. The default value of a boolean column is false and not null.
    const column = this.columnDefinition();
    const columnType = column?.type;
    const value = columnType === 'boolean' ? false : null;
    this.queryValue = { ...this.queryValue, node_type: this.typeTarget.value, value };
    this.updateInput();
    this.queryBuilderOutlet.setQueryProperty(this.pathValue, this.queryValue);
  }

  /**
   * Handles the update of the type value and messages the query controller about the update.
   */
  updateType() {
    const newQueryValue = { node_type: this.typeTarget.value };

    if (this.typeTarget.value === 'is_null') {
      // When this is a condition node with `node_type` is `is_null` no `value`
      // should be given because this tests the equivalence of an empty value.
      // If a value is given the server will ignore it but for the sake of
      // clarity it is removed here.
      newQueryValue.value = null;
      this.inputTarget.classList.toggle('hidden', true);
      this.searchSelectTarget.classList.toggle('hidden', true);
    } else {
      this.updateInput();
    }
    this.queryValue = { ...this.queryValue, ...newQueryValue };
    this.queryBuilderOutlet.setQueryProperty(this.pathValue, newQueryValue);
  }

  /**
   * Handles the update of the value and messages the query controller about the update.
   */
  updateValue(event) {
    const value = (event.target instanceof HTMLSelectElement) ? this.selectTarget.value : this.inputValue();
    this.queryBuilderOutlet.setQueryProperty(this.pathValue, { value });
  }

  /**
   * Handles the deletion of the condition and messages the query controller about the deletion.
   * @param e - the button submit event
   */
  delete(e) {
    e.preventDefault();
    this.queryBuilderOutlet.unsetQueryProperty(this.pathValue);
  }
}
