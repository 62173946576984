import { Controller } from '@hotwired/stimulus';
import AttachmentUpload from './attachment_upload';

export default class AttachmentComponentController extends Controller {
  static targets = [
    'attachmentList',
    'fileList',
    'fileInput',
    'dropzone',
    'overlay',
    'uploadProgressBar',
    'error',
  ];

  static values = {
    allowedFileTypes: Array,
    maxFileSize: Number,
    errorMessages: Object,
  };

  connect() {
    this.url = this.fileInputTarget.dataset.directUploadUrl;
    this.multiple = this.fileInputTarget.getAttribute('multiple') === 'multiple';
    this.bindDropzoneEvents();
    this.bindDirectUploadEvents();
  }

  bindDropzoneEvents() {
    this.dropzoneTarget.addEventListener('drop', (e) => {
      e.preventDefault();
      this.uploadFiles(e.dataTransfer.files);
      this.overlayTarget.classList.add('hidden');
    });

    this.dropzoneTarget.addEventListener('dragstart', () => {
      this.overlayTarget.classList.add('hidden');
    });

    this.dropzoneTarget.addEventListener('dragend', () => {
      this.overlayTarget.classList.add('hidden');
    });

    this.dropzoneTarget.addEventListener('dragover', (e) => {
      e.preventDefault();
      this.overlayTarget.classList.remove('hidden');
    });

    this.dropzoneTarget.addEventListener('dragenter', () => {
      this.overlayTarget.classList.remove('hidden');
    });

    this.dropzoneTarget.addEventListener('dragleave', () => {
      this.overlayTarget.classList.add('hidden');
    });
  }

  bindDirectUploadEvents() {
    this.element.addEventListener('direct-upload:initialize', (event) => {
      const { detail } = event;
      const { id, file } = detail;

      const progressBar = AttachmentComponentController.createUploadProgressBar(id, file);

      if (this.multiple === true) {
        this.fileListTarget.innerHTML += progressBar;
      } else {
        this.fileListTarget.innerHTML = progressBar;
        this.attachmentListTarget.classList.add('hidden');
      }
    });

    this.element.addEventListener('direct-upload:progress', (event) => {
      const { id, progress } = event.detail;

      this.fileListTarget.querySelector(`[id="direct-upload-progress-${id}"]`).style.width = `${progress}%`;
    });

    this.element.addEventListener('direct-upload:error', (event) => {
      event.preventDefault();
      const { id } = event.detail;

      const element = this.fileListTarget.querySelector(`[id="direct-upload-${id}"]`);
      element.classList.remove('opacity-40');
      element.classList.add('text-red-500');
      this.errorTarget.textContent = this.errorMessagesValue.upload_failed;
    });

    this.element.addEventListener('direct-upload:end', (event) => {
      const { id, blob } = event.detail;

      if (blob) {
        this.fileListTarget
          .querySelector(`[id="direct-upload-${id}"]`)
          .appendChild(AttachmentComponentController.createFileInput(this.fileInputTarget.name, blob));
      }

      const element = this.fileListTarget.querySelector(`[id="direct-upload-${id}"]`);
      element.classList.remove('opacity-40');
    });
  }

  fileChanged() {
    const { files } = this.fileInputTarget;
    this.uploadFiles(files);
  }

  removeFile(event) { // eslint-disable-line
    event.target.closest('[data-attachment-component-target="uploadProgressBar"]').remove();

    if (!this.multiple) {
      this.attachmentListTarget.classList.remove('hidden');
    }
  }

  uploadFiles(fileList) {
    const files = Array.from(fileList);

    this.errorTarget.classList.add('hidden');

    if (this.validateFiles(files)) {
      this.fileInputTarget.value = null;
      files.forEach((file) => this.upload(file));
    } else {
      this.errorTarget.classList.remove('hidden');
    }
  }

  validateFiles(files) {
    if (!this.validAmount(files)) {
      this.errorTarget.textContent = this.errorMessagesValue.invalid_amount;
      return false;
    }

    if (!this.validFileTypes(files)) {
      this.errorTarget.textContent = this.errorMessagesValue.invalid_type;
      return false;
    }

    if (!this.validFileSizes(files)) {
      this.errorTarget.textContent = this.errorMessagesValue.invalid_size;
      return false;
    }

    return true;
  }

  validAmount(files) {
    return this.multiple || files.length === 1;
  }

  validFileTypes(files) {
    return this.allowedFileTypesValue.length === 0
      || files.filter((file) => !this.allowedFileTypesValue.includes(file.type)).length === 0;
  }

  validFileSizes(files) {
    return !this.maxFileSizeValue || files.filter((file) => file.size > this.maxFileSizeValue).length === 0;
  }

  upload(file) {
    new AttachmentUpload(file, this.url, this).start();
  }

  static createFileInput(name, blob) {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('id', blob.signed_id);
    input.setAttribute('value', blob.signed_id);
    input.setAttribute('name', name);

    return input;
  }

  static createUploadProgressBar(id, file) {
    return `
    <div id="direct-upload-${id}"
         class="text-sm text-slate-600 px-1 py-1 mb-1 overflow-auto opacity-40" 
         data-attachment-component-target="uploadProgressBar">
      <div class="float-left w-[calc(100%_-_1.25rem)]">
        <div class="w-full bg-slate-200 rounded-full h-2.5">
          <div id="direct-upload-progress-${id}"
               class="bg-primary-600 h-2.5 rounded-full transition-width duration-150 ease-out"
               style="width: 0%"></div>
        </div>
        <span>
          ${file.name} <span class="whitespace-nowrap">(${(file.size / 1048576).toFixed(2)} MB)</span>
        </span>
      </div>
      <i class="fas fa-xmark fa-sm float-right w-5 hover:text-slate-800 cursor-pointer pointer-events-auto"
         data-action="click->attachment-component#removeFile"></i>
    </div>`;
  }
}
