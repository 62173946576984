import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['expand', 'hide', 'rotate', 'class'];

  static values = {
    class: String,
  };

  expand(event) {
    event.preventDefault();
    this.expandTargets.forEach((item) => {
      item.classList.remove('hidden');
    });
    this.hideTargets.forEach((item) => {
      item.classList.add('hidden');
    });
    this.rotateTargets.forEach((item) => {
      item.classList.add('rotate-180');
    });
    this.classTargets.forEach((item) => {
      item.classList.remove(this.classValue);
    });
  }

  collapse(event) {
    event.preventDefault();
    this.expandTargets.forEach((item) => {
      item.classList.add('hidden');
    });
    this.hideTargets.forEach((item) => {
      item.classList.remove('hidden');
    });
    this.rotateTargets.forEach((item) => {
      item.classList.remove('rotate-180');
    });
    this.classTargets.forEach((item) => {
      item.classList.add(this.classValue);
    });
  }

  toggle(event) {
    event.preventDefault();
    this.expandTargets.forEach((item) => {
      item.classList.toggle('hidden');
    });
    this.hideTargets.forEach((item) => {
      item.classList.toggle('hidden');
    });
    this.rotateTargets.forEach((item) => {
      item.classList.toggle('rotate-180');
    });
    this.classTargets.forEach((item) => {
      item.classList.toggle(this.classValue);
    });
  }
}
