import { Controller } from '@hotwired/stimulus';

// Converts the organization name to a lowercase alpha numeric dasherized subdomain string
export default class extends Controller {
  static targets = [
    'subdomainInput',
  ];

  transform(event) {
    this.subdomainInputTarget.value = event.target.value
      .toLowerCase()
      .replace(/[^a-z0-9-\s]/g, '')
      .trim()
      .replace(/\s+/g, '-');
  }
}
