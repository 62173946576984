import { Controller } from '@hotwired/stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  static values = {
    id: Number, // The `id` of the import model.
    channel: String,
    type: String, // The class of the import model e.g. `BankTransactionImport`
    current: String,
  };

  connect() {
    this.subscription = consumer.subscriptions.create({
      channel: this.channelValue,
      id: this.idValue,
      type: this.typeValue,
    }, {
      received: (data) => {
        if (data.status && data.status !== this.currentValue) {
          // NOTE: Prevent immediate page reload because for small files the
          // import finishes right after the page has loaded.
          setTimeout(() => { window.location.reload(); }, 2000);
        }
      },
    });
  }

  disconnect() {
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription);
    }
  }
}
