import { Controller } from '@hotwired/stimulus';

/*
 * Controller for sending window events
 *
 * Give the element that calls the emit action an attribute with the name 'event-name'.
 * This controller will then create a new window event with the given name every time the emit action is triggered.
 *
 * This controller is used for sending signals to controllers which can no be the parent of this controller.
 * For example a button to open a modal. See:
 * https://github.com/compliance-innovations/raemonda/blob/1303058535d3bc6086231e626dbf48bad92c7847
 *    /lib/component_previews/modal_component_preview/closed.html.erb
 */
// Connects to data-controller="window-event"
export default class extends Controller {
  // eslint-disable-next-line class-methods-use-this
  emit(event) {
    event.preventDefault();
    window.dispatchEvent(new CustomEvent(event.target.getAttribute('event-name')));
  }
}
