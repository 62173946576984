import { Controller } from '@hotwired/stimulus';

/*
 * Controller for conditionally showing parts of a form depending on the state of other form elements
 *
 * If the `inputChanged` action is triggered this controller gets the value of the element that triggered
 * the event and compares it with the condition string. If they are equal it shows the contents of
 * the element target, otherwise it wil hide the element target.
 *
 * Usage:
 * 1. Set the condition and initial value
 *    E.g `data-conditional-input-condition-value='with_contra_accounts'
 *      data-conditional-input-initial-value='<%= cash_expectation.contra_bank_account_mode %>'`
 * 2. create an element with the target element.
 *    E.g. `<div data-conditional-input-target="element">`
 * 3. Call the `inputChanged` function on inputs that should decide if the element should be hidden or visible.
 *    E.g. `form.collection_radio_buttons :contra_bank_account_mode, CashExpectation.contra_bank_account_modes.keys,
 *      :to_s, ->(v) { human_enum_value(CashExpectation, :contra_bank_account_modes, v) }, {},
 *      { 'data-action' => 'change->conditional-input#inputChanged' }`
 */

export default class extends Controller {
  static values = { condition: String, initial: String };

  static targets = ['element'];

  connect() {
    this.toggleElement(this.initialValue === this.conditionValue);
  }

  inputChanged(event) {
    this.toggleElement(event.target.value === this.conditionValue);
  }

  toggleElement(visible) {
    this.elementTarget.classList.toggle('hidden', !visible);
  }
}
