import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.replace('translate-y-2', 'translate-y-0');
      this.element.classList.remove('sm:translate-y-0');
      this.element.classList.replace('sm:translate-x-2', 'sm:translate-x-0');
      this.element.classList.replace('opacity-0', 'opacity-100');
    }, 1);

    this.scheduledClose = setTimeout(() => this.close(), 5000);
  }

  close() {
    if (this.scheduledClose) {
      clearTimeout(this.scheduledClose);
    }

    this.element.addEventListener('transitionend', () => {
      this.element.remove();
    });

    this.element.classList.replace('ease-out', 'ease-in');
    this.element.classList.replace('duration-300', 'duration-100');
    this.element.classList.replace('opacity-100', 'opacity-0');
  }
}
