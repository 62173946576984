import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['profileButton', 'profileMenu', 'menuButton', 'menuBars', 'menuCross', 'menu'];

  disconnect() {
    // NOTE: Ensure that the menu is closed only when there is a menu.
    if (this.hasMenuButtonTarget) {
      this.close();
    }
  }

  toggleMenu() {
    const expanded = this.menuButtonTarget.getAttribute('aria-expanded');
    if (expanded === 'true') {
      this.close();
    } else {
      this.open();
    }
  }

  close() {
    this.menuTarget.classList.replace('max-h-500', 'max-h-0');
    this.menuBarsTarget.classList.remove('hidden');
    this.menuCrossTarget.classList.add('hidden');
    this.menuButtonTarget.setAttribute('aria-expanded', 'false');
  }

  open() {
    this.menuTarget.classList.replace('max-h-0', 'max-h-500');
    this.menuBarsTarget.classList.add('hidden');
    this.menuCrossTarget.classList.remove('hidden');
    this.menuButtonTarget.setAttribute('aria-expanded', 'true');
  }
}
