import { config, library, dom } from '@fortawesome/fontawesome-svg-core';

import {
  faAddressCard,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowsToEye,
  faArrowRightArrowLeft,
  faArrowTrendUp,
  faBan,
  faBars,
  faBell,
  faBolt,
  faBook,
  faBuilding,
  faBuildingFlag,
  faCakeCandles,
  faChartLine,
  faCloud,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCircleNotch,
  faCirclePlus,
  faCircleQuestion,
  faClockRotateLeft,
  faComment,
  faCreditCard,
  faDownload,
  faEnvelope,
  faExternalLink,
  faFile,
  faFileArrowUp,
  faFileContract,
  faFileImport,
  faFilter,
  faFlag,
  faFlagCheckered,
  faFolder,
  faFolderPlus,
  faGear,
  faHandcuffs,
  faHouse,
  faIndustry,
  faKey,
  faLinkSlash,
  faListCheck,
  faMagnifyingGlass,
  faMaximize,
  faMedal,
  faMinimize,
  faMinus,
  faMoneyBill,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faMoneyCheckDollar,
  faNewspaper,
  faPen,
  faPenToSquare,
  faPersonRifle,
  faPhone,
  faPlay,
  faPlus,
  faPrint,
  faReceipt,
  faRobot,
  faRotate,
  faRotateLeft,
  faRotateRight,
  faSackDollar,
  faShieldBlank,
  faShuffle,
  faSitemap,
  faStethoscope,
  faTag,
  faTrash,
  faTriangleExclamation,
  faUser,
  faUserDoctor,
  faUserGroup,
  faUserPlus,
  faUserTie,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
// Import available icons

import {
  faGithub,
  faMarkdown,
} from '@fortawesome/free-brands-svg-icons';

// Change the config to fix the flicker
config.mutateApproach = 'sync';

// Add imported icons to library
// NOTE: Please also add icons to the preview in `lib/component_previews/icon_preview.rb`
library.add(
  faAddressCard,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowsToEye,
  faArrowTrendUp,
  faArrowRightArrowLeft,
  faBan,
  faBars,
  faBell,
  faBolt,
  faBook,
  faBuilding,
  faBuildingFlag,
  faCakeCandles,
  faChartLine,
  faCloud,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCircleNotch,
  faCirclePlus,
  faCircleQuestion,
  faClockRotateLeft,
  faComment,
  faCreditCard,
  faDownload,
  faEnvelope,
  faExternalLink,
  faFile,
  faFileArrowUp,
  faFileContract,
  faFileImport,
  faFilter,
  faFlag,
  faFlagCheckered,
  faFolder,
  faFolderPlus,
  faGear,
  faGithub,
  faHandcuffs,
  faHouse,
  faIndustry,
  faKey,
  faLinkSlash,
  faListCheck,
  faMarkdown,
  faMagnifyingGlass,
  faMaximize,
  faMedal,
  faMinimize,
  faMinus,
  faMoneyBill,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faMoneyCheckDollar,
  faNewspaper,
  faPen,
  faPenToSquare,
  faPersonRifle,
  faPhone,
  faPlay,
  faPlus,
  faPrint,
  faReceipt,
  faRobot,
  faRotate,
  faRotateLeft,
  faRotateRight,
  faSackDollar,
  faShieldBlank,
  faShuffle,
  faSitemap,
  faStethoscope,
  faTag,
  faTrash,
  faTriangleExclamation,
  faUser,
  faUserDoctor,
  faUserGroup,
  faUserPlus,
  faUserTie,
  faXmark,
);

dom.watch();
