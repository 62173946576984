import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

// Connects to data-controller="table-row"
export default class extends Controller {
  static values = {
    path: String,
  };

  click(event) {
    if (event.target.closest('a') == null
      && event.target.closest('button') == null
      && event.target.closest('input') == null) {
      Turbo.visit(this.pathValue);
    }
  }

  middleClick(event) {
    if (event.target.closest('a') == null
      && event.target.closest('button') == null
      && event.target.closest('input') == null) {
      window.open(this.pathValue, '_blank');
    }
  }
}
