import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';

// Connects to data-controller="popover"
export default class extends Controller {
  static targets = [
    'popper',
    'arrow',
  ];

  static values = {
    boundary: String,
  };

  connect() {
    useClickOutside(this);

    const width = this.element.offsetWidth;
    const height = this.element.offsetHeight;
    const popperWidth = this.popperTarget.offsetWidth;
    const popperHeight = this.popperTarget.offsetHeight;
    const boundingWidth = this.boundingWidth();
    const boundingHeight = this.boundingHeight();
    const boundingLeft = this.boundingLeft();
    const boundingTop = this.boundingTop();
    const offsetX = 20; // These offsets visually align the arrow to the bottom center of the item the popover refers to
    const offsetY = 8;

    let translateX = width / 2 - offsetX;
    let translateY = height + offsetY;
    let popperPositionBottom = true;
    let popperPositionRight = true;

    // If the popper does not fit to the right and does fit to the left show it on the left
    if ((boundingLeft + width / 2 + popperWidth + offsetX) > boundingWidth && boundingLeft > popperWidth) {
      translateX = -(popperWidth - width / 2 - offsetX);
      popperPositionRight = false;
    }

    // If the popper does not fit to the bottom but does fit to the top show it on the top
    if ((boundingTop + height + popperHeight + offsetY) > boundingHeight && boundingTop > popperHeight) {
      translateY = -(popperHeight + offsetY);
      popperPositionBottom = false;
    }

    this.popperTarget.style.inset = '0 auto auto 0';
    this.popperTarget.style.transform = `translate(${translateX}px, ${translateY}px)`;
    this.addArrow(popperPositionBottom, popperPositionRight);
  }

  disconnect() {
    this.hide();
  }

  addArrow(popperPositionBottom, popperPositionRight) {
    if (!popperPositionBottom && popperPositionRight) {
      this.arrowTarget.classList.add('arrow-bottom-left');
    } else if (popperPositionBottom && !popperPositionRight) {
      this.arrowTarget.classList.add('arrow-top-right');
    } else if (!popperPositionBottom && !popperPositionRight) {
      this.arrowTarget.classList.add('arrow-bottom-right');
    } else {
      this.arrowTarget.classList.add('arrow-top-left');
    }
  }

  clickOutside() {
    this.hide();
  }

  show() {
    this.popperTarget.classList.remove('invisible');
    this.popperTarget.classList.remove('opacity-0');
  }

  hide() {
    this.popperTarget.classList.add('invisible');
    this.popperTarget.classList.add('opacity-0');
  }

  boundaryElement() {
    if (this.boundaryValue) {
      return this.element.closest(this.boundaryValue); // find a parent element matching the selector
    }
    return null;
  }

  boundingWidth() {
    // Width of the screen or bounding element
    return this.boundaryElement()?.offsetWidth || document.documentElement.clientWidth;
  }

  boundingHeight() {
    // Height of the full html page or bounding element
    return this.boundaryElement()?.offsetHeight || document.body.scrollHeight;
  }

  boundingLeft() {
    // Distance to left side of screen or bounding element
    return this.element.getBoundingClientRect().left - (this.boundaryElement()?.getBoundingClientRect().left || 0);
  }

  boundingTop() {
    // Distance to top of full html page or bounding element
    return this.element.getBoundingClientRect().top + window.scrollY
      - (this.boundaryElement() ? this.boundaryElement().getBoundingClientRect().top + window.scrollY : 0);
  }
}
