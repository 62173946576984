import { Controller } from '@hotwired/stimulus';

/**
 * Controller for adding and removing nested forms.
 *
 * Example usage:
 *
 * <div data-controller="nested-form">
 *   <a data-nested-form-target="target" data-action="click->nested-form#add">Add</a>
 *   <template data-nested-form-target="template">
 *      <%= form.fields_for :my_association, MyObject.new, child_index: 'NEW_RECORD' do |nested_form| %>
 *         <%= nested_form.text_field :association_property %>
 *         <%= nested_form.hidden_field :_destroy %>
 *         <a data-action="click->nested-form#remove">Remove</a>
 *      <% end %>
 *   </template>
 * </div>
 *
 * Note it is important to assign the nested form a child_index of `NEW_RECORD`. The child_index should be unique.
 * Therefore, `NEW_RECORD` is replaced by a timestamp when adding a new nested form.
 * The nested form template should contain a hidden `_destroy` field, which is used for marking entries for destruction.
 */
export default class NestedFormController extends Controller {
  static targets = [
    'template',
    'target',
    'wrapper',
  ];

  add(event) {
    event.preventDefault();
    // Based on: https://github.com/stimulus-components/stimulus-rails-nested-form
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    this.targetTarget.insertAdjacentHTML('beforebegin', content);
  }

  /* eslint-disable class-methods-use-this */
  remove(event) {
    event.preventDefault();
    const wrapper = event.target.closest('[data-nested-form-target=wrapper]');
    wrapper.classList.add('hidden');

    // Prevent "An invalid form control with name='' is not focusable" error due to required field on hidden element.
    wrapper.querySelectorAll('[required]').forEach((e) => e.removeAttribute('required'));

    // Mark for destruction
    const destroyInput = wrapper.querySelector("input[name*='_destroy']");
    destroyInput.value = true;
  }
  /* eslint-enable class-methods-use-this */
}
