import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="banksearch"
export default class extends Controller {
  data;

  static targets = ['input', 'list', 'footer', 'more'];

  connect() {
    this.fetchData();
  }

  async fetchData() {
    try {
      // See the README how the bank providers json is made
      const response = await fetch('./files/bank_providers.json');
      const data = await response.json();
      this.data = data;
      this.renderList();
      this.setMoreCounter();
    } catch (error) {
      this.inputTarget.disabled = true;
      this.data = [
        { name: 'ING', logo_url: '' },
        { name: 'HSBC', logo_url: '' },
        { name: 'Deutsche Bank', logo_url: '' },
        { name: 'BNP Paribas', logo_url: '' },
        { name: 'Santander', logo_url: '' },
      ];
      this.renderList();
    }
  }

  setMoreCounter() {
    this.moreTarget.getElementsByTagName('span')[0].innerHTML = this.data.length - 5;
  }

  renderList() {
    const limit = 5;
    const searchTerm = this.inputTarget.value.toLowerCase();
    const defaultBanks = new Set(['ING', 'HSBC', 'Deutsche Bank', 'BNP Paribas', 'Santander']);
    let filteredData = this.data.filter((item) => defaultBanks.has(item.name)).slice(0, limit);

    if (searchTerm.length > 0) {
      // filters the data by the search term and sorts the results by position of the search term in the string
      filteredData = this.data.map((item) => ({
        ...item,
        matchIndex: item.name.toLowerCase().indexOf(searchTerm),
      }))
        .filter((item) => item.matchIndex !== -1)
        .sort((a, b) => a.matchIndex - b.matchIndex).slice(0, limit);
    }

    this.listTarget.innerHTML = filteredData
      .map((item) => `<tr>
        <td class="text-secondary-600 px-6 pb-5">
        <img class="w-6 h-6 inline-block mr-4 -mt-1 overflow-hidden" src="${item.logo_url}" alt="- ${item.name} logo">
        ${item.name}
        </td>
        </tr>`)
      .join('');

    this.moreTarget.classList.toggle('hidden', searchTerm.length > 0);
    this.footerTarget.classList.toggle('hidden', filteredData.length > limit - 2);
  }
}
