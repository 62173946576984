import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="bank-authorization-form"
export default class extends Controller {
  static targets = ['singleLimit', 'togetherLimit'];

  static values = { disableReason: String };

  connect() {
    this.update();
  }

  update() {
    if (this.singleLimitTarget.value.trim()) {
      this.togetherLimitTarget.disabled = false;
      this.togetherLimitTarget.classList.remove('bg-slate-50');
      this.togetherLimitTarget.title = false;
    } else {
      this.togetherLimitTarget.disabled = true;
      this.togetherLimitTarget.classList.add('bg-slate-50');
      this.togetherLimitTarget.value = '';
      this.togetherLimitTarget.title = this.disableReasonValue;
    }
  }
}
