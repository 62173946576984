/* The following get method is inspired by Lodash and based on
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore?tab=readme-ov-file#_get
 */

/**
 * Gets a value from a object at the given path.
 * @param {Object} obj - The object to get the value from
 * @param {string} path - The path for the object where the value is at
 * @param defaultValue - A default value if no value is found at the path location
 */
function get(obj, path, defaultValue = undefined) {
  const travel = (regexp) => String.prototype.split
    .call(path, regexp)
    .filter(Boolean)
    .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
}

export default get;
