import { Turbo } from '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import './channels';
import './controllers';
import './fontawesome';

ActiveStorage.start();

/*
 * Turbo disables submit buttons when a submit has started. The FormSubmission.requestFinished method of Turbo removes
 * the disabled attribute just before emitting a TurboSubmitEndEvent. The user is redirected to another page after the
 * TurboSubmitEndEvent (unless using turbo streams), however, in the meantime the user sees the submit button suddenly
 * being enabled again. This is similar to this old bug in
 * rails-ujs: https://github.com/rails/rails/pull/31441. To resolve this, we directly disable the buttons again on a
 * turbo:submit-end event. As noted, in case turbo streams is used the user remains on the same page after
 * submitting the form. Hence, we only re-disable the submit buttons on a redirect (as is done in the aforementioned
 * pull request).
 * A ticket has been opened to fix this issue in Turbo: https://github.com/hotwired/turbo/issues/766
 */
document.addEventListener('turbo:submit-end', (event) => {
  if (event.detail.fetchResponse.response.redirected === true) {
    event.target.querySelectorAll('[type=submit]').forEach((button) => {
      button.disabled = true;
    });
  }
});

/**
 * Returns if the string has a json structure
 * @param {String|JSON} string possible json string
 * @returns Boolean
 */
function hasJsonStructure(string) {
  try {
    JSON.parse(string);
    return true;
  } catch (err) {
    return false;
  }
}

/**
 * Shows a styled confirmation dialog and returns true if the user confirms it, optionally enables the confirm button
 * if the confirmation input is present and correctly filled
 * @param {String|JSON} message If a string is given it looks for a dialog with id 'default-confirm-dialog', replaces
 * the title and opens it. If a JSON string is given it looks for a dialog with the id given in the JSON string.
 * @returns Promise<Boolean>
 *
 * @example When using a string as message
 * // opens the 'default-confirm-dialog' and replaces the title with the message string
 * <a data-turbo-method="get" data-turbo-confirm="Are you sure you want to perform this action?">foo</a>
 *
 * @example When using a custom dialog
 * // opens the 'custom-confirm-dialog' and handles the confirmation input if present
 * <a data-turbo-method="get" data-turbo-confirm="{"id":"custom-confirm-dialog"}">foo</a>
 *
 * @example When setting a title and content
 * // opens the 'default-confirm-dialog' and sets title and content
 * <a data-turbo-method="get" data-turbo-confirm="{"title":"foo", "content":"bar"}">foo</a>
 */
function confirmMethod(message) {
  let dialog;
  if (hasJsonStructure(message)) {
    const json = JSON.parse(message);
    if ('id' in json) {
      dialog = document.getElementById(json.id);
    } else {
      dialog = document.getElementById('default-confirm-dialog');
    }

    if ('title' in json) {
      dialog.getElementsByClassName('dialog-title')[0].innerHTML = json.title;
    }

    if ('content' in json) {
      dialog.getElementsByClassName('dialog-content')[0].innerHTML = json.content;
    }
  } else {
    dialog = document.getElementById('default-confirm-dialog');
    dialog.getElementsByClassName('dialog-title')[0].innerHTML = message;
    dialog.getElementsByClassName('dialog-content')[0].innerHTML = '';
  }
  dialog.showModal();

  const input = dialog.querySelector("input[name='confirm-string']");
  const button = dialog.querySelector("button[value='confirm']");
  if (input) {
    input.addEventListener('input', (event) => {
      button.disabled = (event.target.value !== event.target.dataset.pattern);
    });
  }

  return new Promise((resolve) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue === 'confirm');
    }, { once: true });
  });
}

Turbo.setConfirmMethod((message) => confirmMethod(message));
