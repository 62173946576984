import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkboxAll', 'checkbox', 'formActions', 'rowHighlight'];

  toggleAll(e) {
    const value = e.target.checked;
    this.checkboxTargets.forEach((element) => this.toggleCheckbox(element, value));
  }

  toggle(e) {
    this.toggleCheckbox(e.target, e.target.checked);
  }

  toggleCheckbox(element, value) {
    const row = element.closest('tr');
    const rowHighlight = row.querySelector('[data-table-component-target="rowHighlight"]');

    if (value) {
      row.classList.add('bg-slate-50');
      rowHighlight.classList.remove('hidden');
      element.checked = true;
    } else {
      row.classList.remove('bg-slate-50');
      rowHighlight.classList.add('hidden');
      element.checked = false;
    }

    if (this.hasFormActionsTarget) {
      this.toggleFormActions();
    }

    this.toggleCheckboxAll();
  }

  toggleFormActions() {
    if (this.anyChecked()) {
      this.formActionsTarget.classList.remove('hidden');
    } else {
      this.formActionsTarget.classList.add('hidden');
    }
  }

  toggleCheckboxAll() {
    // When any checkbox is checked the checkboxAll should uncheck all checkboxes when it being clicked. Hence, also
    // checkboxAll should be unchecked after being clicked. By setting checked to true if any checkbox is checked, we
    // ensure that the checkbox is unchecked when a user clicks on it again. Else, checkboxAll would become checked.
    this.checkboxAllTarget.checked = this.anyChecked();
    this.checkboxAllTarget.indeterminate = this.anyChecked() && this.amountChecked() < this.checkboxTargets.length;
  }

  anyChecked() {
    return this.amountChecked() > 0;
  }

  amountChecked() {
    return this.checkboxTargets.filter((element) => element.checked).length;
  }
}
