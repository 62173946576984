import { Controller } from '@hotwired/stimulus';

/*
 * Controller for interactive forms using turbo-frames
 *
 * If the `update` action is triggered this controller sends the serialized
 * form to the server on the designated `url` using a GET request. The form is
 * disabled and a spinner is shown during loading. The server should respond by
 * re-rendering the form such that any changes are reflected.  This is then
 * shown in the turbo-frame.
 *
 * Usage:
 * 1. Use the `form_turbo_frame` helper (see `FormHelper`) which creates a
 *  `turbo_frame_tag` for this form.
 * 2. Set the form target in the form-element.
 *    E.g. `form_with(..., data: { form_target: :form, turbo_frame: '_top' }) do |form| ...`
 * 3. Call the `update` function on inputs that should re-render the form.
 *    E.g. `form.select :type, enum_select_choices(Party, :types), {}, { 'data-action' => 'change->form#update' }`
 * 4. Implement the server such that a new form is rendered based on the changed properties
 */

export default class extends Controller {
  static targets = ['form', 'spinnerOverlayTemplate', 'spinner'];

  update(event) {
    event.preventDefault();
    // Disable file inputs such that these are not sent (file inputs cannot be sent in a GET request)
    this.formTarget.querySelectorAll('input[type=file]').forEach((fileInput) => {
      fileInput.disabled = true;
    });
    const formData = new FormData(this.formTarget);

    const params = new URLSearchParams(formData);
    // Extract the query (i.e. search) part from the url and combine it with
    // the form data.
    // A URL requires a host but there is only a path so the current host is used.
    let url = new URL(this.element.dataset.url, `${window.location.protocol}//${window.location.host}`);
    const urlParams = new URLSearchParams(url.search);
    urlParams.entries().forEach(([key, val]) => {
      params.append(key, val);
    });

    // Remove the query and dummy host from the url
    url.search = '';
    url = url.pathname;

    this.disableFormElements();
    this.showSpinner(event.currentTarget);

    // Combine the form data and params from the url to a final path.
    this.element.src = `${url}?${params.toString()}`;
  }

  /**
   * @param formInput the form element / input that triggered the form to
   * update. This is used in order to show the spinner at the same height as
   * this element to ensure that it is visible.
   */
  showSpinner(formInput) {
    this.element.insertAdjacentHTML('beforeend', this.spinnerOverlayTemplateTarget.innerHTML);

    // Position the spinner at the same height as the formInput that triggered
    // the form to update
    const relativeYPosition = formInput.getBoundingClientRect().top - this.spinnerTarget.getBoundingClientRect().top;
    this.spinnerTarget.style.position = 'relative';
    this.spinnerTarget.style.top = `${relativeYPosition}px`;

    // Show the spinner after 500ms delay
    setTimeout(() => { this.element.lastElementChild.classList.remove('opacity-0'); }, 500);
  }

  disableFormElements() {
    // `this.formTarget.elements` is a `HTMLFormControlsCollection` which is
    // first converted to an `Array` such that `forEach` can be used rather
    // than a for-loop.
    [...this.formTarget.elements].forEach((element) => { element.disabled = true; });
  }
}
