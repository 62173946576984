import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'container', 'overlay', 'menu', 'close'];

  toggle() {
    const expanded = this.containerTarget.getAttribute('aria-expanded');
    if (expanded === 'true') {
      this.overlayTarget.classList.replace('opacity-100', 'opacity-0');
      this.menuTarget.classList.replace('translate-x-0', '-translate-x-full');
      this.closeTarget.classList.replace('opacity-100', 'opacity-0');
      this.containerTarget.setAttribute('aria-expanded', 'false');
      setTimeout(() => {
        this.containerTarget.classList.add('hidden');
      }, 300);
    } else {
      this.containerTarget.classList.remove('hidden');
      setTimeout(() => {
        this.overlayTarget.classList.replace('opacity-0', 'opacity-100');
        this.menuTarget.classList.replace('-translate-x-full', 'translate-x-0');
        this.closeTarget.classList.replace('opacity-0', 'opacity-100');
        this.containerTarget.setAttribute('aria-expanded', 'true');
      }, 5);
    }
  }
}
