import { Controller } from '@hotwired/stimulus';

export default class NameComponentController extends Controller {
  static targets = [
    'firstName',
    'middleName',
    'surname',
    'fullName',
  ];

  autoCompleteFullName() {
    this.fullNameTarget.value = [this.firstNameTarget, this.middleNameTarget, this.surnameTarget]
      .map((x) => x.value)
      .filter((x) => x)
      .join(' ');
  }
}
