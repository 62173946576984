import { Controller } from '@hotwired/stimulus';

/**
 * Controller responsible for a query group
 * Renders the buttons of a query group based on the given subquery. Communicates query group changes to query builder
 * controller using an outlet. Has a path to denote the location of the group in the query. This is
 * used by the query builder to apply the changes to the query.
 * Connects to data-controller="query-group"
 */
export default class extends Controller {
  static outlets = ['query-builder'];

  static values = {
    query: Object,
    path: String,
    definition: Object,
  };

  static targets = [
    'andButton',
    'orButton',
    'notButton',
    'existsButton',
    'deleteButton',
    'addConditionButton',
    'addGroupButton',
    'association',
  ];

  /**
   * Activates the correct group type button, removes the delete group button for the root group and initializes
   * the has_many group options (the exists group type button and has_many association select field).
   */
  connect() {
    this.activateButton(this.getActiveButtonFromQueryValue());
    if (this.isRootGroup()) this.deleteButtonTarget.remove(); // Cannot delete the root group

    const hasManyAssociations = this.definitionValue.associations != null
                                  && this.definitionValue.associations.length > 0;
    this.existsButtonTarget.disabled = !hasManyAssociations;
    if (hasManyAssociations) this.initializeAssociationSelectOptions();
  }

  /**
   * Determines using the node_type and negation properties of the queryValue which button should be active.
   */
  getActiveButtonFromQueryValue() {
    if (this.queryValue.node_type === 'and' && this.queryValue.negation === true) {
      return this.notButtonTarget;
    }
    if (this.queryValue.node_type === 'and') {
      return this.andButtonTarget;
    }
    if (this.queryValue.node_type === 'or') {
      return this.orButtonTarget;
    }
    if (this.queryValue.node_type === 'has_many') {
      return this.existsButtonTarget;
    }
    return null;
  }

  /**
   * Add has_many associations that are defined in the definition as option to the associationTarget select.
   */
  initializeAssociationSelectOptions() {
    this.definitionValue.associations.forEach((association) => {
      // Ensure that the user's subscription supports the association by
      // checking whether definition exists in definitionsValue
      if (this.queryBuilderOutlet.findAssociationDefinition(this.definitionValue, association.name)) {
        const selected = association.name === this.queryValue.association_name;
        this.associationTarget.name = this.pathValue.concat('.association_name');
        this.associationTarget.appendChild(new Option(association.human_name, association.name, false, selected));
      }
    });
  }

  isRootGroup() {
    return this.pathValue === '';
  }

  /**
   * Activates a particular button and deactivates the others.
   */
  activateButton(element) {
    [
      this.notButtonTarget,
      this.andButtonTarget,
      this.orButtonTarget,
      this.existsButtonTarget,
    ].forEach((button) => {
      button.setAttribute('aria-selected', element === button);
    });

    const isHasManyButton = element === this.existsButtonTarget;
    // Show the association select when the button that is being activated is a has many button (exists).
    this.associationTarget.classList.toggle('hidden', !isHasManyButton);
    this.addConditionButtonTarget.disabled = isHasManyButton; // A has many node can not have conditions.
    // A has many node can only have a single group. When the group is already present the add group button should
    // be disabled.
    this.addGroupButtonTarget.disabled = isHasManyButton && this.queryValue.node != null;
  }

  /**
   * Handles selecting a logical node type (and/or/not) and sends the new value via the outlet to the
   * query builder controller.
   */
  selectLogicalType(e) {
    e.preventDefault();
    this.activateButton(e.target);
    const negation = e.params.negation ? e.params.negation : false;

    const value = {
      node_type: e.params.type,
      negation,
    };

    if (this.queryValue.node_type === 'has_many') {
      // The node type changes from a has_many node to a logical node. This means we need to set the nodes property
      // to an initial value. Else, we leave it untouched such that changing between and/or/not does not
      // unnecessarily reset all the subgroups/conditions. Furthermore, we unset the properties `node` and
      // `association_name` since they are only relevant for has_many nodes.
      // Note, it is assumed here that `has_many` is the only none logical node_type.
      value.nodes = [];
      this.queryBuilderOutlet.unsetQueryProperty(this.pathValue.concat('.node'), false);
      this.queryBuilderOutlet.unsetQueryProperty(this.pathValue.concat('.association_name'), false);
    }

    this.queryBuilderOutlet.setQueryProperty(this.pathValue, value, true);
  }

  /**
   * Handles selecting a has_many node type (exists) and sends the new value via the outlet to the
   * query builder controller.
   */
  selectHasManyType(e) {
    e.preventDefault();
    this.activateButton(e.target);

    const value = {
      node_type: 'has_many',
      association_name: this.associationTarget.value,
    };

    if (this.queryValue.node_type !== 'has_many') {
      // The node type changes from a logical node to a has_many node. This means we need to set the node property
      // to an initial value. Else, we leave it untouched such that changing between exists does not
      // unnecessarily reset all the subgroups/conditions. Furthermore, we unset the properties `nodes` and `negation`
      // since they are only relevant for logical nodes.
      value.node = { node_type: 'and', nodes: [] };
      this.queryBuilderOutlet.unsetQueryProperty(this.pathValue.concat('.nodes'), false);
      this.queryBuilderOutlet.unsetQueryProperty(this.pathValue.concat('.negation'), false);
    }

    this.queryBuilderOutlet.setQueryProperty(this.pathValue, value, true);
  }

  /**
   * Handles selecting an association and sends the new value via the outlet to the query builder controller.
   */
  selectAssociation() {
    const value = { association_name: this.associationTarget.value };
    const oldModel = this.queryBuilderOutlet.findAssociationDefinition(
      this.definitionValue,
      this.queryValue.association_name,
    ).model;
    const newModel = this.queryBuilderOutlet.findAssociationDefinition(
      this.definitionValue,
      this.associationTarget.value,
    ).model;

    if (oldModel !== newModel) {
      // Reset the node to a default value if the model of the association changes since the conditions might not be
      // applicable anymore. Else, we leave it untouched such that changing the association does not unnecessarily
      // reset the subgroup.
      value.node = { node_type: 'and', nodes: [] };
    }

    this.queryBuilderOutlet.setQueryProperty(this.pathValue, value, true);
  }

  /**
   * Handles adding a new group and sending the new value via the outlet to the query controller
   * @param e - the button press event
   */
  addGroup(e) {
    e.preventDefault();
    // Has_many nodes have a single subnode while in contrast other nodes can contain multiple subnodes
    // (groups/constraints).

    const value = { node_type: 'and', nodes: [], negation: false };

    if (this.queryValue.node_type === 'has_many') {
      this.queryBuilderOutlet.setQueryProperty(`${this.pathValue}.node`, value, true);
    } else {
      this.queryBuilderOutlet.appendQueryProperty(`${this.pathValue}.nodes`, value);
    }
  }

  /**
   * Handles adding a new condition and sending the new value via the outlet to the query controller
   * @param e - the button press event
   */
  addCondition(e) {
    e.preventDefault();
    this.queryBuilderOutlet.appendQueryProperty(`${this.pathValue}.nodes`, {
      node_type: 'equals',
      column: null,
      value: null,
    });
  }

  /**
   * Handles the deletion of the group and sends a delete message via the outlet to the query controller
   * @param e - the button press event
   */
  deleteGroup(e) {
    e.preventDefault();
    this.queryBuilderOutlet.unsetQueryProperty(this.pathValue);
  }
}
