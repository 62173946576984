import { Controller } from '@hotwired/stimulus';
import set from '../support/set';
import unset from '../support/unset';
import get from '../support/get';
import debounce from '../support/debounce';

/**
 * Query builder controller.
 * Attaches to a textarea and generates a query builder form based on the json in the text area. Changes made via the
 * query builder form are applied to the json in the text area. Communication with the form elements in the query
 * builder form happens via Stimulus outlets. The containing condition and group controller calls the
 * `appendQueryProperty`, `setQueryProperty` or `unsetQueryProperty` methods of this controller to apply
 * changes to the query json. If needed the query controller rerenders the form elements.
 * Connects to data-controller="query-builder"
 */
export default class extends Controller {
  static values = {
    // Object representation of the JSON query in the textarea
    query: Object,
    // The definition of this query (selected by the users)
    definition: Object,
    // An array of definitions that are available. This is used for rendering has_many groups on associated models.
    definitions: Array,
  };

  static targets = [
    'query',
    'textarea',
    'alert',
    'groupTemplate',
    'conditionTemplate',
    'advancedChevron',
  ];

  groupTypes = ['and', 'or', 'has_many'];

  /**
   * Initializes the query value and renders it.
   */
  connect() {
    this.initializeQueryValue();
    this.debouncedReRender = debounce(() => this.reRender(), 500);
    this.render();
  }

  /**
   * Renders the full query. If the query does not start with a group node it shows a warning.
   */
  render() {
    this.textareaTarget.value = JSON.stringify(this.queryValue, null, '  ');

    if (this.groupTypes.includes(this.queryValue.node_type)) {
      this.alertTarget.classList.add('hidden');
      this.queryTarget.innerHTML = this.renderGroup(this.queryValue, '', this.definitionValue).innerHTML;
    } else {
      this.alertTarget.classList.remove('hidden');
    }
  }

  /**
   * Renders a group based on the group template and recursively renders its subqueries
   * @param subquery A subgroup of the original queryValue object.
   * @param path Object property path indicating where in the queryValue object the subquery that is being rendered is
   *   located. This is used by the query_condition_controller/query_group_controller to update the queryValue whenever
   *   the user changes the group/conditions in the UI.
   * @param definition The definition of the group. Subgroups of a has_many group are rendered using a definition that
   *   belongs to the has_many association the user has selected such that conditions can be added on that associated
   *   model.
   * @return {HTMLDivElement} A query group element.
   */
  renderGroup(subquery, path, definition) {
    const groupElement = document.createElement('div');
    groupElement.innerHTML = this.groupTemplateTarget.innerHTML;

    const { dataset } = groupElement.firstElementChild;
    dataset.queryGroupQueryValue = JSON.stringify(subquery);
    dataset.queryGroupPathValue = path;
    dataset.queryGroupDefinitionValue = JSON.stringify(definition);
    dataset.controller = 'query-group';
    dataset.queryGroupQueryBuilderOutlet = '#query-builder';

    if (subquery.node_type !== 'has_many' && subquery.nodes != null) {
      subquery.nodes.forEach((node, index) => {
        groupElement.lastElementChild.append(this.renderNode(node, `${path}.nodes[${index}]`, definition));
      });
    }

    if (subquery.node_type === 'has_many' && subquery.node != null) {
      const associationDefinition = this.findAssociationDefinition(definition, subquery.association_name);
      groupElement.lastElementChild.append(this.renderNode(subquery.node, `${path}.node`, associationDefinition));
    }

    return groupElement;
  }

  /**
   * Renders either a (sub)group or a condition based on the node_type.
   */
  renderNode(node, path, definition) {
    if (this.groupTypes.includes(node.node_type)) {
      return this.renderGroup(node, path, definition);
    }

    return this.renderCondition(node, path, definition);
  }

  /**
   * Search the definition based on a association_name the user has selected for a has_many group.
   * Has_many groups use a definition of the has_many association that the user has selected
   * such that conditions can be added on the columns of that associated has_many model.
   */
  findAssociationDefinition(definition, associationName) {
    if (associationName != null) {
      const associatedType = definition.associations.find((d) => d.name === associationName).type;
      return this.definitionsValue.find((d) => d.name === associatedType);
    }
    return null;
  }

  /**
   * Renders a condition based on the condition template and attaches a query condition controller.
   * @param node A condition node
   * @param path Object property path indicating where in the queryValue object the condition node that is being
   *   rendered is located. This is used by the query_condition_controller to update the queryValue whenever the
   *   user changes the conditions in the UI.
   * @param definition The definition of the model for which conditions can be added.
   * @return {HTMLDivElement} A query condition element.
   */
  renderCondition(node, path, definition) {
    const conditionElement = document.createElement('div');
    conditionElement.innerHTML = this.conditionTemplateTarget.innerHTML;

    conditionElement.firstElementChild.dataset.queryConditionDefinitionValue = JSON.stringify(definition);
    conditionElement.firstElementChild.dataset.queryConditionQueryValue = JSON.stringify(node);
    conditionElement.firstElementChild.dataset.queryConditionPathValue = path;
    conditionElement.firstElementChild.dataset.controller = 'query-condition';
    conditionElement.firstElementChild.dataset.queryConditionQueryBuilderOutlet = '#query-builder';
    return conditionElement;
  }

  /**
   * Toggles the displaying of the `Advanced` textarea that contains the where conditions of the query as JSON.
   */
  toggleAdvanced() {
    this.textareaTarget.classList.toggle('hidden');
    this.advancedChevronTarget.classList.toggle('rotate-180');
  }

  /**
   * Sets the value of the query based on the JSON value of the textarea. Returns a default query if it is empty.
   */
  initializeQueryValue() {
    try {
      this.alertTarget.classList.add('hidden');

      const query = JSON.parse(this.textareaTarget.innerText);
      if (Object.keys(query).length === 0 || this.textareaTarget.innerText === '') {
        this.queryValue = { node_type: 'and', nodes: [], negation: false };
      }

      this.queryValue = query;
    } catch (e) {
      this.queryValue = { node_type: 'and', nodes: [], negation: false };
    }
  }

  /**
   * Re-renders the query builder when the json is manually changed. Only renders if the json is valid to make manual
   * editing possible. Shows a banner if the json is invalid.
   */
  reRender() {
    try {
      // We check for valid JSON before setting the query to make manual editing possible.
      this.queryValue = JSON.parse(this.textareaTarget.value);
      this.render();
    } catch (e) {
      this.alertTarget.classList.remove('hidden');
    }
  }

  /**
   * Gets the current value at the specified object property path and appends the values at that path to the query value
   * and renders the new query value. It requires the current value of queryValue at `path` to be an array.
   */
  appendQueryProperty(path, value) {
    const oldValue = get(this.queryValue, path);
    this.queryValue = set(this.queryValue, path, oldValue.concat(value));
    this.render();
  }

  /**
   * Set the query value at the specified property path with the specified values. It updates the textarea with the
   * new JSON value of the query. It optionally re-renders the UI based on the new queryValue.
   */
  setQueryProperty(path, values, render = false) {
    Object.entries(values).forEach(([key, value]) => {
      this.queryValue = set(this.queryValue, path.concat('.', key), value);
    });
    this.textareaTarget.value = JSON.stringify(this.queryValue, null, '  ');
    if (render) this.render();
  }

  /**
   * Unsets a property at the specified property path from the query value and renders the new query value.
   * It optionally re-renders the UI based on the new queryValue.
   */
  unsetQueryProperty(path, render = true) {
    const obj = this.queryValue;
    unset(obj, path);
    this.queryValue = obj;

    if (render) this.render();
  }
}
