/* The following set method is inspired by Lodash and based on
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore?tab=readme-ov-file#_get
 */

/**
 * Sets a value of a object at the given path. Builds the intermediate objects and arrays if needed.
 * @param {Object} obj - The object to set the value on
 * @param {string} path - The path for the object where the value should be set
 * @param value - The value that should be set.
 */
function set(obj, path, value) {
  if (typeof obj !== 'object' || obj === null) return obj;

  const keys = Array.isArray(path)
    ? path
    : String.prototype.split.call(path, /[,[\]]+?/)
      .filter(Boolean)
      .reduce((res, key) => res.concat(key.split(/[.[\]]+?/).filter(Boolean)), []);

  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    } else if (!acc[key] || (typeof acc[key] !== 'object' && typeof acc[key] !== 'function')) {
      acc[key] = /^[0-9]+$/.test(keys[index + 1]) ? [] : {};
    }
    return acc[key];
  }, obj);

  return obj;
}

export default set;
