import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="aasm-form"
export default class extends Controller {
  static targets = [
    'form', 'select', 'input',
  ];

  connect() {
    if (this.hasSelectTarget) {
      this.updateAction();
    }
  }

  updateAction() {
    this.formTarget.action = this.selectTarget.value;
    this.inputTargets.forEach((input) => {
      if (input.dataset.aasmFormShowFor === this.selectTarget.value) {
        input.classList.remove('hidden');
        input.classList.add('sm:grid');
        input.querySelectorAll('input, select, textarea').forEach((node) => { node.disabled = false; });
      } else {
        input.classList.add('hidden');
        input.classList.remove('sm:grid');
        input.querySelectorAll('input, select, textarea').forEach((node) => { node.disabled = true; });
      }
    });
  }
}
