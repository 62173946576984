import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

// Connects to data-controller="paginate"
export default class extends Controller {
  static values = {
    path: String,
  };

  submit(event) {
    event.preventDefault();

    const url = new URL(this.pathValue, window.location.href);
    const params = url.searchParams;
    params.set(event.target.name, event.target.value);
    url.search = params.toString();

    Turbo.visit(url);
  }
}
