/* The following unset method is inspired by Lodash and based on
 * https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore?tab=readme-ov-file#_get
 */

/**
 * Unsets a value from a object at the given path.
 * @param {Object} obj - The object to unset the value of
 * @param {string} path - The path for the object where the value is at
 */
function unset(obj, path) {
  if (typeof obj !== 'object' || obj === null) return false;

  const keys = Array.isArray(path)
    ? path
    : String.prototype.split.call(path, /[,[\]]+?/)
      .filter(Boolean)
      .reduce((res, key) => res.concat(key.split(/[.[\]]+?/).filter(Boolean)), []);

  const lastKey = keys.pop();

  const parent = keys.reduce((acc, key) => ((acc !== null && acc !== undefined) ? acc[key] : undefined), obj);

  if (parent && typeof parent === 'object') {
    if (Array.isArray(parent)) {
      parent.splice(lastKey, 1);
    } else {
      delete parent[lastKey];
    }
    return true;
  }

  return false;
}

export default unset;
