import { DirectUpload } from '@rails/activestorage';

export default class AttachmentUpload {
  constructor(file, url, controller) {
    this.directUpload = new DirectUpload(file, url, this);
    this.controller = controller;
    this.file = file;
    this.id = this.directUpload.id;

    this.dispatchEvent('direct-upload:initialize', { id: this.id, file: this.file });
  }

  start() {
    this.dispatchEvent('direct-upload:start', { id: this.id });
    this.directUpload.create((error, blob) => {
      if (error) {
        this.dispatchEvent('direct-upload:error', { id: this.id, error });
      } else {
        this.dispatchEvent('direct-upload:end', { id: this.id, blob });
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener(
      'progress',
      (event) => {
        this.dispatchEvent('direct-upload:progress', {
          id: this.id,
          progress: (event.loaded / event.total) * 100,
        });
      },
    );
  }

  dispatchEvent(name, detail) {
    this.controller.element.dispatchEvent(new CustomEvent(name, { detail }));
  }
}
